.about {
  display: flex;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.title {
  flex: 1;
  display: flex;
  justify-self: flex-start;
  font-size: 1.3em;
  color: #55ae00;
  font-weight: 600;
  padding: 10px;
}

.content {
  flex: 3;
  font-size: 1.2em;
  color: #808090;
  font-weight: 600;
  padding: 10px;
}

@media only screen and (max-width: 768px) {
  .title,
  .content {
    flex-basis: 100%;
  }
  .about {
    width: 90%;
  }
}

.list > li {
  margin-top: 3px;
}

.title-skills {
  align-self: flex-start;
}
